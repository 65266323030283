// Website related settings
const settings = {
  isSplash: true,
  useCustomCursor: false,
  googleTrackingID: "",
};

//Home Page
const greeting = {
  title: "Hello 👋🏼",
  title2: "Fluqzy",
  logo_name: "Fluqzy",
  nickname: "Fluqzy",
  full_name: "Tim",
  subTitle: "Developer, Gamer, Open Source Enthusiast, Life Long Learner.",
  resumeLink: "https://discord.gg/mfsdS6ugp3",
  mail: "mailto:tim@fluqzy.eu",
};

const socialMediaLinks = {
  github: "https://github.com/Fluqzy/",
  youtube: "https://www.youtube.com/channel/UCxgHFMBPxP9wyKW3t9dpMrg",
  gmail: "tim@fluqzy.eu",
  twitter: "https://twitter.com/FluqzyDE/",
};

const skills = {
  data: [
    {
      title: "Languages & Apps",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Discord Bots for large Communities",
        "⚡ Integration of third party services such as Firebase / AWS / GCP",
      ],
      softwareSkills: [
        {
          skillName: "VS:Code",
          fontAwesomeClassname: "simple-icons:visualstudiocode",
          style: {
            color: "#007ACC",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#563d7c",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#181717",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Discord",
          fontAwesomeClassname: "simple-icons:discord",
          style: {
            color: "#5865F2",
          },
        },
        {
          skillName: "Django",
          fontAwesomeClassname: "simple-icons:django",
          style: {
            color: "#0c4b33",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Cloud Infrastructure",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "Debian",
          fontAwesomeClassname: "simple-icons:debian",
          style: {
            color: "#A81D33",
          },
        },
        {
          skillName: "Raspberry Pi",
          fontAwesomeClassname: "simple-icons:raspberrypi",
          style: {
            color: "#A22846",
          },
        },
        {
          skillName: "Nginx",
          fontAwesomeClassname: "simple-icons:nginx",
          style: {
            color: "##009639",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#D22128",
          },
        },
        {
          skillName: "Bitwarden",
          fontAwesomeClassname: "simple-icons:bitwarden",
          style: {
            color: "#175DDC",
          },
        },
        {
          skillName: "Nextcloud",
          fontAwesomeClassname: "simple-icons:nextcloud",
          style: {
            color: "#0082C9",
          },
        },
        {
          skillName: "Portainer",
          fontAwesomeClassname: "simple-icons:portainer",
          style: {
            color: "#13BEF9",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Werner-von-Siemens-Gymnasium Bad Harzburg",
      subtitle: "Highschool degree",
      logo_path: "WVS_Logo.png",
      alt_name: "School",
      duration: "2018 - 2025",
      descriptions: ["⚡  I'm currently pursuing my Highschool degree."],
      website_link: "https://wvsharzburg.de", // Hier musst du noch deine Schule eintragen, wenn du willst
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Melion",
      subtitle: "Python Certified Bot Developer",
      logo_path: "melion.png",
      alt_name: "pythonbotdev",
      color_code: "#32ff7e",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: "I will add my professional expieriences here.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        // {
        //   title: "Professorenhelfer",
        //   company: "TU München",
        //   company_url: "https://www.tum.de/",
        //   logo_path: "Webarch.jfif",
        //   duration: "Mar 2021 - Nov 2021",
        //   location: "TU München",
        //   description:
        //     "Beschreibung",
        //   color: "#4285F4",
        // },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        // {
        //   title: "Professorenhelfer",
        //   company: "TU München",
        //   company_url: "https://www.tum.de/",
        //   logo_path: "Webarch.jfif",
        //   duration: "Mar 2021 - Nov 2021",
        //   location: "TU München",
        //   description:
        //     "Beschreibung",
        //   color: "#4285F4",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create Python Bot Scripts. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "favicon.png",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others.",
    link: "https://discord.gg/mfsdS6ugp3",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "World of Nutshell",
      url: "https://discord.gg/mfsdS6ugp3",
      description: "A Discord full of self coded bots.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
    {
      id: "1",
      name: "Tacty",
      url: "https://tacty.org",
      description:
        "Tacty is an all-rounder security Discord bot with a focus on easily control managed by its own API and CDN.",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
